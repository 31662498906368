import React, { Component } from 'react'
import { connect } from 'react-redux'
import AspectRatios from '../common-items/AspectRatios'
import * as RecorderActions from '../../../redux/actions/RecorderActions'
import * as SnapGridBoxActions from '../../../redux/actions/SnapGridBoxActions'
import RatioDropDown from '../common-items/RatioDropDown'
import ManWalkAction from '../common-items/ManWalkAction'
//import ShootAction from '../common-items/ShootAction'
import AudioMic from '../common-items/audio-mic'
// import CollaboratorAction from '../common-items/Collaborator';
import LowerNotifyModal from '../../modals/LowerNotifyModal'
import RatioAction from '../common-items/RatioAction'
import * as ProjectEditActions from '../../../redux/actions/ProjectEditActions'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'
import { toast } from 'react-toastify'
import {
  checkSaved,
  clearSaved,
  getShowSize,
  setSnapSize,
  toggleShowSaveTip,
} from '../../../methods/recorder/RatioActionMethods'

const aspectRatioExist = (aspectRatioArr) => {
  let valid = false
  aspectRatioArr.map((x) => {
    if (x.height && x.width) {
      valid = true
    }
  })
  return valid
}

class ReplayActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSnapGrid: false,
      startAudio: false,
      manWalk: false,
      showSetRatioDropdown: false,
      orientationVal: 'landscape',
      showLowerNotify: false,
      cameraOnBtn: false,
    }

    this.mounted = false
    this.getShowSize = getShowSize.bind(this) // get replay video show size(video showing size, browser size)
    this.checkSaved = checkSaved.bind(this)
    this.setSnapSize = setSnapSize.bind(this)
    this.clearSaved = clearSaved.bind(this)
    this.toggleShowSaveTip = toggleShowSaveTip.bind(this)

    this.moveRef = props.moveRef
    this.showCameraBtnCount = React.createRef()
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      this.setState({
        orientationVal: this.props.cameraDimension.orientation,
      })

      window.addEventListener('orientationchange', this.handleOrientationChange)

      setInterval(() => {
        const val = this.showCameraBtnCount.current || 0
        if (val < 2) {
          this.showCameraBtnCount.current = val + 1
        } else {
          this.setState({ cameraOnBtn: true })
        }
      }, 1000)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.replayVideo &&
      this.props.replayVideo !== prevProps.replayVideo &&
      this.props.isReplayingVideo &&
      this.props.isLoadedReplayVideo
    ) {
      setTimeout(() => {
        const { replayVideo } = this.props

        this.props.setMediaOriginSize({
          width: replayVideo.videoWidth,
          height: replayVideo.videoHeight,
        })

        if (this.mounted) {
          this.setState({
            startAudio: true,
            showSnapGrid: this.props.snapGridPosition.set,
          })
        }

        this.setMediaOrientDimension()
      }, 500)
    }
  }

  componentWillUnmount() {
    if (this.mounted) {
      window.removeEventListener(
        'orientationchange',
        this.handleOrientationChange,
      )

      this.setState({
        showSetRatioDropdown: false,
      })
    }

    this.mounted = false
  }

  addNewAspectRatio() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
      savedLayouts,
      selectedRatio,
    } = this.props
    const { isProPlan, isFreePlan } = SubscriptionManager(
      authUser,
      userSubscription,
      products,
    )
    if (isFreePlan) {
      return alertSubscriptionMessage(
        'Looks like you’re killing it on your socials. To create aspect ratios, please upgrade to a paid plan.',
      )
    }
    if (
      !isProPlan &&
      savedLayouts.filter((x, index) => x && index !== selectedRatio).length >=
        2
    ) {
      return alertSubscriptionMessage(
        'Looks like you’re killing it on your socials. To create up to 4 aspect ratios simultaneously, please upgrade to the Cerebriam Pro package.',
      )
    }
    this.props.selectRatio(this.props.selectedRatio)
    this.props.resetSelectedAspectRatios()
    this.checkSaved()
  }

  handleOpenCamera = () => {
    this.props.onOpenVideoInput()
  }

  toggleShowSnapGrid = (r) => {
    if (this.mounted) {
      this.setState({
        showSnapGrid: r,
      })
    }
  }

  setChangeRatio = () => {
    this.props.setSnapGridTransform('none', this.props.media_layout)

    this.toggleShowSnapGrid(false)

    setTimeout(() => {
      this.toggleShowSnapGrid(true)
    }, 500)
  }

  toggleSetRatioDropdown = (flag) => {
    if (this.mounted) {
      this.setState({
        showSetRatioDropdown: flag,
      })
    }
  }

  handleOrientationChange = () => {
    if (
      this.mounted &&
      this.props.replayVideo &&
      this.props.isReplayingVideo &&
      this.props.isLoadedReplayVideo
    ) {
      this.toggleShowSnapGrid(false)

      setTimeout(() => {
        this.setMediaOrientDimension()

        this.toggleShowSnapGrid(true)
      }, 500)
    }
  }

  setMediaOrientDimension = () => {
    let cRect = this.props.videoActionWrapper.current.getBoundingClientRect()
    let orientVal = cRect.width > cRect.height ? 'landscape' : 'vertical'
    if (this.mounted) {
      this.setState({
        orientationVal: orientVal,
      })
    }

    const { replayVideo, appearedLowerMediaNotify, media_layout, resolution } =
      this.props

    // set up resolution, snap grid rect size.
    let ratioValue = 1080
    if (replayVideo.videoWidth === 1080 || replayVideo.videoWidth === 1920) {
      ratioValue = 1080
    } else if (
      replayVideo.videoWidth > 1920 &&
      replayVideo.videoWidth <= 3840
    ) {
      ratioValue = 2160
    } else if (
      replayVideo.videoWidth >= 720 &&
      replayVideo.videoWidth <= 1280 &&
      replayVideo.videoWidth !== 1080 &&
      replayVideo.videoWidth < 1920
    ) {
      ratioValue = 720
    } else if (replayVideo.videoWidth < 720) {
      ratioValue = 480
    }

    let m_layout = media_layout
    if (ratioValue <= 1080) {
      if (orientVal === 'vertical') {
        if (media_layout === 0) {
          m_layout = 1
          this.props.setSelectedMediaLayout(false, 0)
          this.props.setSelectedMediaLayout(true, 1)
        }
      } else if (orientVal === 'landscape') {
        if (media_layout === 1) {
          m_layout = 0
          this.props.setSelectedMediaLayout(true, 0)
          this.props.setSelectedMediaLayout(false, 1)
        }
      }
    }

    const quality = {
      1080: 0,
      720: 1,
      480: 2,
    }

    let ind = quality[resolution]
    if (ratioValue === 480 || ratioValue === 720) {
      this.props.setMediaResolution(
        resolution === 1080 ? ratioValue : resolution,
      )

      ind = quality[resolution === 1080 ? ratioValue : resolution]
    } else {
      this.props.setMediaResolution(1080)
    }

    if ((ind === 2 || ratioValue === 480) && !appearedLowerMediaNotify) {
      if (this.mounted) {
        this.setState({
          showLowerNotify: true,
        })
      }

      this.props.setAppearedLowerNotify(true)
    }

    this.props.setCameraDimension({
      ratio: ratioValue,
      orientation: orientVal,
    })

    this.props.setMediaLayout(m_layout)
  }

  tapGridAttentionPosition = (evt) => {
    if (!this.props.snapGridPosition.set) {
      let touch = evt.changedTouches[0]

      this.props.setSnapGridPosition({
        set: true,
        top: touch.clientY,
        left: touch.clientX,
      })

      this.setChangeRatio()
    }
  }

  closeNotifyModal = () => {
    if (this.mounted) {
      this.setState({
        showLowerNotify: false,
      })
    }
  }

  uploadRecordedVideo = () => {
    if (!aspectRatioExist(this.props.aspectSize)) {
      triggerGoogleAnalyticsEvent('exception', {
        description:
          'Whoa there! Select your target social platform, then hit the "+" button once before pressing "Make"',
        userId: this.props.authUser?.id,
      })
      return toast.warning(
        'Whoa there! Select your target social platform, then hit the "+" button once before pressing "Make"',
      )
    }
    this.props.onUploadRecordVideo()
  }

  render() {
    const {
      isReplayingVideo,
      isLoadedReplayVideo,
      isUploadingVideo,
      isUploadedVideo,
      replayVideo,
      showSnap,
      setScaleValue,
    } = this.props

    if (
      isReplayingVideo &&
      isLoadedReplayVideo &&
      replayVideo &&
      replayVideo.videoWidth
    ) {
      const {
        showSetRatioDropdown,
        manWalk,
        startAudio,
        showLowerNotify,
        showSnapGrid,
      } = this.state

      const { MediaStream, snapGridPosition } = this.props

      return (
        <div
          className="relative w-full h-full mobile-actions cursor-pointer"
          ref={this.props.videoActionWrapper}
          key="mobileActionWrapper"
          onTouchEnd={this.tapGridAttentionPosition}>
          {showLowerNotify && (
            <LowerNotifyModal closeNotifyModal={this.closeNotifyModal} />
          )}

          {/* <ManWalkAction manWalk={manWalk} /> */}

          {/* <AspectRatios toggleSetRatioDropdown={this.toggleSetRatioDropdown} /> */}

          {/* {showSetRatioDropdown && (
            <RatioDropDown
              toggleShowSnapGrid={this.toggleShowSnapGrid}
              toggleSetRatioDropdown={this.toggleSetRatioDropdown}
            />
          )} */}
          {snapGridPosition.set && (
            <button
              className="add-ratio-btn"
              onClick={() => this.addNewAspectRatio()}
              style={{ position: 'absolute', right: 5, top: 18, zIndex: 100 }}>
              +
            </button>
          )}

          {this.props.videoActionWrapper?.current &&
            replayVideo &&
            showSnapGrid &&
            showSnap && (
              <RatioAction
                cameraVideo={replayVideo}
                videoActionWrapper={this.props.videoActionWrapper}
                direction="center"
                layoutChangedFromDropDown={this.props.layoutChangedFromDropDown}
                changeLayoutFromDropdown={this.props.changeLayoutFromDropdown}
                moveRef={this.props.moveRef}
                selNextPopup={this.props.selNextPopup}
                transform={
                  window.innerWidth < 992
                    ? `translate(${window.innerWidth / 2 - 186 / 2}px, ${
                        window.innerHeight / 2 - 105 / 2
                      }px)`
                    : `translate(${window.innerWidth / 2 - 867 / 2}px, ${
                        window.innerHeight / 2 - 488 / 2
                      }px)`
                }
              />
            )}

          {/* <ShootAction
            stream={MediaStream}
            setChangeRatio={this.setChangeRatio}
            setScaleValue={setScaleValue}
          /> */}

          <div className="absolute mic-action flex items-center justify-end lg:w-1/2 xs:w-full md:w-1/2 right-0">
            <div className="mic-wrapper">
              {startAudio && (
                <AudioMic
                  backgroundColor="#ffffff"
                  strokeColor="#008180"
                  stream={MediaStream}
                  className={'w-full'}
                />
              )}
            </div>
            {/* <div className="collaborator-action">
              <CollaboratorAction />
            </div> */}
          </div>

          {snapGridPosition.set && (
            <div className="absolute w-full flex justify-center mobile-replay-action">
              <button
                className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 mr-2"
                onClick={this.handleOpenCamera}>
                Retry
              </button>

              <button
                className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3"
                onClick={this.uploadRecordedVideo.bind(this)}>
                Make
              </button>
            </div>
          )}
        </div>
      )
    } else if (
      !isReplayingVideo &&
      !isUploadingVideo &&
      !isUploadedVideo &&
      this.state.cameraOnBtn
    ) {
      return (
        <div
          className="w-full h-full block flex justify-center items-center mobile-actions"
          key="mobileActionWrapper">
          <button
            className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 mr-2"
            style={{
              width: '300px',
              height: '50px',
            }}
            onClick={this.handleOpenCamera}>
            Turn on Camera
          </button>
        </div>
      )
    } else {
      return (
        <div
          className="relative w-full h-full mobile-actions"
          key="mobileActionWrapper"
        />
      )
    }
  }
}

const mapStateToProps = (state) => ({
  media_layout: state.recorder.media_layout,
  snapGridPosition: state.recorder.snapGridPosition,
  resolution: state.recorder.resolution,
  cameraDimension: state.recorder.cameraDimension,
  appearedLowerMediaNotify: state.recorder.appearedLowerMediaNotify,
  authUser: state.auth.user,
  saveLayout: state.recorder.saveLayout,
  shoot_value: state.recorder.shoot_value,
  currentLayout: state.videoCrop.currentLayout,
  zoomAbleState: state.recorder.zoomAbleState,
  subscriptions: state.subscriptions,
})

const mapDispatchToProps = (dispatch) => ({
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setSnapGridTransform: (...args) =>
    dispatch(SnapGridBoxActions.setSnapGridTransform(...args)),
  setSnapGridPosition: (...args) =>
    dispatch(SnapGridBoxActions.setSnapGridPosition(...args)),
  setMediaLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setCameraDimension: (...args) =>
    dispatch(SnapGridBoxActions.setCameraDimension(...args)),
  setMediaResolution: (...args) =>
    dispatch(RecorderActions.setMediaResolution(...args)),
  setAppearedLowerNotify: (...args) =>
    dispatch(RecorderActions.setAppearedLowerNotify(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReplayActions)
