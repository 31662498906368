import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Dropzone from 'react-dropzone'
import { setUploadFileType } from '../../redux/actions/RecorderActions'
import * as EditorActions from '../../redux/actions/EditorActions'
import { libraryFilesUpload } from '../../services/UploadServices'
import {
  handleFileSelected,
  inputWrapper,
} from '../../methods/home/HomeMethods'
import {
  ASSET_LIBRARY_ACCEPTED_FORMATS,
  ASSET_LIBRARY_EXTENSIONS,
  ASSET_LIBRARY_FOLDERS,
  ASSET_LIBRARY_LABELS,
} from '../../constants/AssetLibraries'
import { CAMERA_VIEW, CROPPER } from '../../constants/Routes'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  isValidUploadUrl,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import instance from '../../utils/Axios'
import { RecordIcon } from '../../components/home/icons/RecordIcon'
import { VideoIcon } from '../../components/home/icons/VideoIcon'
import { AudioIcon } from '../../components/home/icons/AudioIcon'
import { PhotoIcon } from '../../components/home/icons/PhotoIcon'
import { BrandingIcon } from '../../components/home/icons/BrandingIcon'
import { PresentationIcon } from '../../components/home/icons/PresentationIcon'
import { StoryboardIcon } from '../../components/home/icons/StoryboardIcon'
import { UploadIcon } from '../../components/home/icons/UploadIcon'
import { TickIcon } from '../../components/home/icons/TickIcon'

import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal'
import LibraryAssetUploader from '../../services/library-assets/LibraryAssetUploader'
import { ConvertDuration } from '../../utils/TimeManage'
import { SOCKET, SOCKET_EVENTS } from '../../utils/Socket'

class UploadFrame extends Component {
  constructor(props) {
    super(props)

    this.state = {
      UploadList: ASSET_LIBRARY_LABELS,
      UploadIcons: [
        () => <VideoIcon />,
        () => <AudioIcon />,
        () => <PhotoIcon />,
        () => <BrandingIcon />,
        () => <PresentationIcon />,
        () => <StoryboardIcon />,
      ],
      extensionList: ASSET_LIBRARY_EXTENSIONS,
      acceptList: ASSET_LIBRARY_ACCEPTED_FORMATS,
      assetsLibs: ASSET_LIBRARY_FOLDERS,
      selAcceptType: 0,
      UploadingFile: false,
      uploadVideModal: false,
      scenesSelectionModal: false,
      selectAllScenes: false,
      selectedScenes: [],
      videoUrl: '',
      segementationChecked: false,
      segmentedVideosLibAssets: [],
      selectedSegmentedVideos: [],
    }

    this.uploaderInput = React.createRef()

    this.handleFileSelected = handleFileSelected.bind(this)
    this.libraryFilesUpload = libraryFilesUpload.bind(this)
    this.inputWrapper = inputWrapper.bind(this)
  }

  componentDidMount() {
    this.openSocketConnection()
  }
  componentWillUnmount() {
    SOCKET.off(SOCKET_EVENTS.receiveSegmentedScenes)
  }

  openSocketConnection = async () => {
    SOCKET.on(SOCKET_EVENTS.receiveSegmentedScenes, async (data) => {
      console.log('RECEIVED SEGMENTED SCENES', data)
      // Check user
      if (data?.user_id === this.props.authUser.id) {
        await this.saveSegmentations(data?.results)
      }
    })
  }

  selectSegmentedVideo = (videoName) => {
    const currState = this.state.selectedSegmentedVideos
    const index = currState.indexOf(videoName)
    const selectAllScenes = this.state.selectAllScenes
    if (index > -1) {
      currState.splice(index, 1)
    } else {
      currState.push(videoName)
    }
    this.setState({
      selectedSegmentedVideos: currState,
      selectAllScenes:
        index > -1
          ? false
          : currState.length === this.state.segmentedVideosLibAssets.length
          ? true
          : selectAllScenes,
    })
  }

  moveSegmentationsToCropper = () => {
    const selectedVids = this.state.selectedSegmentedVideos
    if (!selectedVids.length) {
      return toast.error('Select atleast one segmentation')
    }
    const selectedVideos = this.state.segmentedVideosLibAssets.filter((video) =>
      selectedVids.includes(video.fileName),
    )
    this.props.history.push({
      pathname: CROPPER,
      state: {
        videoUrl: selectedVideos[0].fileUrl,
        videoId: selectedVideos[0]._id,
        videoDuration: selectedVideos[0].duration,
        segmentedVideos: selectedVideos,
      },
    })
  }

  toggleSelectAllScenes = () => {
    const prevState = this.state.selectAllScenes
    const newSelectedScenes = !prevState
      ? this.state.segmentedVideosLibAssets.map(({ fileName }) => fileName)
      : []
    this.setState({
      selectAllScenes: !prevState,
      selectedSegmentedVideos: newSelectedScenes,
    })
  }

  toggleVideoUploadModal = () => {
    const prevState = this.state.uploadVideModal
    this.setState({
      uploadVideModal: !prevState,
    })
  }

  toggleScenesSelectionModal = () => {
    const prevState = this.state.scenesSelectionModal
    this.setState({
      scenesSelectionModal: !prevState,
    })
  }

  toggleSegmentation = (e) => {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
    } = this.props
    return toast.warning(
      'We’re upgrading this feature - email us through the Settings menu if you need it sooner!',
    )
    // if (!SubscriptionManager(authUser, userSubscription, products)?.isProPlan) {
    //   return alertSubscriptionMessage(
    //     'Nice idea to repurpose something you\'ve already edited for a different aspect ratio, just upgrade to the Cerebriam Pro package to get access to the auto scene segmentation feature.',
    //   )
    // }
    // this.setState({
    //   segementationChecked: e.target.checked,
    // })
  }

  /**
   * click category
   *
   * @param upload
   * @param i
   */
  onClickList = (upload, i) => {
    triggerGoogleAnalyticsEvent('upload_content', {
      value: upload,
    })
    if ([4, 5].includes(i)) {
      toast.dismiss()
      return toast.warning(
        'Due for release soon, join our newsletter for latest updates',
      )
    }
    this.props.setUploadFileType(upload)

    this.setState({
      selAcceptType: i,
    })

    if (!this.uploaderInput.current) {
      return false
    }

    if (i === 0) {
      return this.toggleVideoUploadModal()
    }
    setTimeout(() => {
      this.uploaderInput.current.value = null
      this.uploaderInput.current.click()
    }, 200)
  }

  saveSegmentations = async (segVideos) => {
    const authorId = this.props.authUser.id
    const libraryAssets = await Promise.all(
      segVideos.map((segVid) => {
        return LibraryAssetUploader.saveAsLibraryAsset(
          null,
          segVid[0],
          'medias',
          authorId,
          '',
          {},
          true,
        )
      }),
    )
    this.setState({
      segmentedVideosLibAssets: libraryAssets.map((asset, index) => ({
        ...asset,
        thumbnail: segVideos[index][1],
      })),
    })
    this.props.onUploadingStatus(false)
    return this.toggleScenesSelectionModal()
  }

  uploadUrlVideo = async (e) => {
    e.preventDefault()
    const authorId = this.props.authUser.id
    try {
      this.props.onUploadingStatus(true)
      this.toggleVideoUploadModal()
      const url = this.state.videoUrl
      const response = await instance.post('/user-libraries/url-upload', {
        url,
        segmentVideo: this.state.segementationChecked,
      })
      if (response.data.segmenting) {
        return
      }

      const libraryAsset = await LibraryAssetUploader.saveAsLibraryAsset(
        null,
        response.data.location,
        'medias',
        authorId,
        '',
        {},
        true,
      )
      this.props.onUploadingStatus(false)

      this.props.history.push({
        pathname: CROPPER,
        state: {
          videoUrl: libraryAsset.fileUrl,
          videoId: libraryAsset._id,
          videoDuration: libraryAsset.duration,
        },
      })
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Url provided cannot be uploaded.',
      )
      this.props.onUploadingStatus(false)
    }
  }

  render() {
    const { UploadList, UploadIcons, extensionList } = this.state

    return (
      <div className={'w-full flex justify-center h-full'}>
        {/* <InnerNavigation /> */}
        <div className="w-full overflow-auto uploadPage">
          <p className="pageHeading xs:text-md sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center">
            Upload content for your project
          </p>

          {/* <div className="w-full flex justify-center items-center">
            <p className="xs:text-md sm:text-lg md:text-xl mt-2 text-center xs:w-full sm:w-full md:w-1/3 lg:w-2/3 xxl:w-2/3">
              Upload multimedia content from your desktop or app now if you’d
              like to edit in your project. Use the link below to give
              permission to others in your team, or hired experts, to add media
              or plans to the project. The following file types are acceptable:
            </p>
          </div> */}

          <ul className="w-full listWrape">
            <li
              className="cursor-pointer text-center uploadCards"
              onClick={() => {
                triggerGoogleAnalyticsEvent('upload_content', {
                  value: 'record',
                  userId: this.props.authUser?.id,
                })
                this.props.history.push(CAMERA_VIEW)
              }}>
              <div className="hover:text-green-500 h-full borders-record xs:px-4 xl:px-6 xs:py-4 xl:py-6 upload-border">
                <div className="w-full justify-center items-center items">
                  <div className="icon-container">
                    <RecordIcon />
                  </div>
                </div>
                <p>Record</p>
                <div
                  className="text-base xs:text-xs sm:text-sm"
                  style={{
                    color: '#4B5563',
                  }}>
                  MP4, AVI, MOV, WEBM
                </div>
              </div>
            </li>
            {Array.apply(null, UploadList).map((x, i) => {
              const icon = () => UploadIcons[i]()
              return (
                <li
                  className={
                    'cursor-pointer text-center uploadCards' +
                    (this.props.upload === x ? ' selected-category' : '')
                  }
                  key={i}
                  onClick={() => this.onClickList(x, i)}>
                  <div
                    className={`hover:text-green-500 h-full xs:px-4 xl:px-6 xs:py-4 xl:py-6 ${
                      i < 3 ? 'topr' : 'bottomr'
                    } ${i < 2 ? ' borderb-full' : ''} ${
                      i === 2 ? ' bottom' : ''
                    } upload-border`}>
                    <div className="w-full justify-center items-center items">
                      <div className="icon-container">{icon()}</div>
                    </div>
                    <p>{x}</p>
                    <div
                      className="text-base xs:text-xs sm:text-sm"
                      style={{
                        color: '#4B5563',
                      }}>
                      {extensionList[i]}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <Modal
          size="xl"
          scrollBehavior="outside"
          blockScrollOnMount={false}
          isOpen={this.state.uploadVideModal}
          onClose={this.toggleVideoUploadModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div className="upload-video-modal">
                <h3 className="modal-title">Upload Video</h3>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    this.handleFileSelected(undefined, acceptedFiles)
                    this.toggleVideoUploadModal()
                  }}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <section className="dropzone-section p-2">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop the file here....</p>
                        ) : (
                          <>
                            <div className="upload-icon">
                              <UploadIcon />
                            </div>
                            <p
                              className="mt-2 mb-3"
                              style={{
                                fontSize: 14,
                                color: '#008180',
                                fontWeight: 600,
                              }}>
                              Upload a file
                            </p>
                            <p style={{ fontSize: 12 }}>
                              Click to upload a video
                            </p>
                            <p style={{ fontSize: 12 }}>
                              Or, drag and drop a file here
                            </p>
                          </>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>

                <h2 className="or-text">OR</h2>

                <form className="video-url-form" onSubmit={this.uploadUrlVideo}>
                  <input
                    name="video-url"
                    id="video-url"
                    placeholder="Add URL..."
                    value={this.state.videoUrl}
                    required
                    onChange={(e) => {
                      this.setState({ videoUrl: e.target.value })
                    }}
                  />
                  {this.state.videoUrl &&
                    isValidUploadUrl(this.state.videoUrl) && (
                      <button type="submit btn">Add</button>
                    )}
                </form>

                <div className="form-divider"></div>

                {/* <p className="apply-segmentation">
                  <label className="cerebriam-checkbox">
                    <input
                      type="checkbox"
                      name="apply"
                      onChange={this.toggleSegmentation}
                      checked={this.state.segementationChecked}
                      // onChange={this.toggleScenesSelectionModal}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="apply" htmlFor="apply">
                    <span>
                      Beta: Apply auto segmentation to your video -keep it under
                      90 seconds.{' '}
                      <a
                        href="https://cerebriam.com"
                        target="_blank"
                        rel="noreferrer">
                        What is auto segmentation?
                      </a>
                    </span>
                  </label>
                </p> */}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          size="full"
          scrollBehavior="outside"
          blockScrollOnMount={false}
          isOpen={this.state.scenesSelectionModal}
          onClose={this.toggleScenesSelectionModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton style={{ fontSize: 20, color: '#4B5563' }} />
            <ModalBody>
              <div className="scenes-selection-modal">
                <h3 className="modal-title">Scenes from your video</h3>
                <h2>
                  If none are found, try cropping and manually cutting scenes in
                  our editor.{' '}
                </h2>
                <div
                  className="select-all-container"
                  onClick={this.toggleSelectAllScenes}>
                  <div
                    className={`select-radio${
                      this.state.selectAllScenes ? ' active' : ''
                    }`}>
                    {this.state.selectAllScenes && <TickIcon />}
                  </div>
                  <p>Select All</p>
                </div>

                <div className="scenes">
                  {this.state.segmentedVideosLibAssets.map(
                    (segmentedAsset, index) => {
                      return (
                        <div
                          key={index}
                          className="scene"
                          onClick={() =>
                            this.selectSegmentedVideo(segmentedAsset.fileName)
                          }>
                          <div className={'scene-img'}>
                            <img
                              src={segmentedAsset.thumbnail}
                              alt="scene-screenshot"
                            />
                          </div>
                          <span className="scene-duration">
                            {ConvertDuration(segmentedAsset.duration * 1000)}
                          </span>
                          <div
                            className={`select-radio scene-radio${
                              this.state.selectedSegmentedVideos.includes(
                                segmentedAsset.fileName,
                              )
                                ? ' active'
                                : ''
                            }`}>
                            <TickIcon />
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>

                <div className="buttons">
                  <button
                    className="btn back"
                    onClick={this.toggleScenesSelectionModal}>
                    Go Back
                  </button>
                  <button
                    className="btn next"
                    onClick={this.moveSegmentationsToCropper}>
                    Next
                  </button>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
        {this.inputWrapper()}
      </div>
    )
  }
}

/**
 * Map video state props
 * @param state
 * @returns {{upload: "Best Practices" | "Stylistic Issues" | "Variables" | "Possible Errors" | string | *}}
 */
const mapStateToProps = (state) => ({
  upload: state.recorder.upload,
  authUser: state.auth.user,
  subscriptions: state.subscriptions,
  project: state.editor.project,
})

const mapDispatchToProps = (dispatch) => ({
  setUploadFileType: (...args) => dispatch(setUploadFileType(...args)),
  updateProject: (...args) => dispatch(EditorActions.updateProject(...args)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UploadFrame))
