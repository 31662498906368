import { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  removeAuthErrors,
  trialRegisterUser,
} from '../../redux/actions/AuthActions'
import { toast } from 'react-toastify'

const ReviewConfirm = ({
  activeCycle,
  activeProduct,
  paymentTypeText,
  currentPlan,
  show,
  registerFormData,
}) => {
  const dispatch = useDispatch()
  const {
    auth: { registerError, signUpLoading },
  } = useSelector((state) => state)

  const signUp = () => {
    dispatch(trialRegisterUser(registerFormData))
  }

  useEffect(() => {
    if (registerError) {
      toast.dismiss()
      toast.error(registerError)

      dispatch(removeAuthErrors())
    }
  }, [registerError])

  return (
    <div
      className="cerebriam-review-confirm cerebriam-container"
      style={{ display: show ? 'block' : 'none' }}>
      <div className="cerebriam-review-confirm__content">
        <h2>Review & Confirm</h2>
        <p className="description">
          Let's make sure everything looks correct.
          {activeCycle !== 3 && 'You can cancel your trial before it ends.'}
        </p>
        <div className="cerebriam-price-summary">
          <div className="cerebriam-item">
            <span>
              {activeProduct === 1
                ? paymentTypeText.productOne
                : activeProduct === 2
                ? paymentTypeText.productTwo
                : ''}
            </span>
            <span>
              {activeCycle === 1
                ? paymentTypeText.cycleOne
                : activeCycle === 2
                ? paymentTypeText.cycleTwo
                : activeCycle === 3
                ? paymentTypeText.cycleThree
                : ''}
            </span>
            <span>
              {activeCycle === 1
                ? currentPlan?.price_annually
                : activeCycle === 2
                ? currentPlan?.price_monthly
                : activeCycle === 3
                ? currentPlan?.price_pay_as_you_go
                : ''}
            </span>
          </div>
          <br />
          {/* <div className="cerebriam-item">
            <span>
              Total due today
              {activeCycle === 2 && ' and paid every month'}
            </span>
            <span>
              {activeCycle === 1
                ? currentPlan?.price_annually
                : activeCycle === 2
                ? currentPlan?.price_monthly
                : activeCycle === 3
                ? currentPlan?.price_pay_as_you_go
                : ''}
            </span>
          </div> */}
          <div className="cerebriam-confirm">
            <button onClick={signUp} disabled={signUpLoading}>
              Complete Order{' '}
              {signUpLoading && (
                <FontAwesomeIcon className="spinner" icon={faSpinner} />
              )}
            </button>
          </div>
          <p className="further-desc">
            {activeCycle !== 3 &&
              `Your subscription will automatically renew ${
                activeCycle === 1 ? 'annually' : 'each month'
              }.`}{' '}
            By completing this purchase you agree to our{' '}
            <a
              href="https://web.cerebriam.com/terms-conditions/"
              target="_blank"
              rel="noreferrer">
              Terms Of Service
            </a>{' '}
            and{' '}
            <a
              href="https://web.cerebriam.com/privacy-policy/"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default ReviewConfirm
