import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { registerUser, removeAuthErrors } from '../../redux/actions/AuthActions'
import { getAllSubscriptionProducts } from '../../redux/actions/SubscriptionActions'
import { HOME, PRICING } from '../../constants/Routes'
import {
  getLocation,
  getRegion,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'

import Surfer from '../../assets/images/landing-page/surfer.png'
import { PaymentConstants } from '../../constants/Payments'
import { checkCoupon } from '../../services/SubscriptionService'

const ErrorPill = ({ message }) => {
  return <span className="error">{message}</span>
}

const SignUpModal = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  return (
    <section className="cerebriam-signup">
      <div className="darkBG" onClick={() => setIsSignUpOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="surfer-image">
            <img src={Surfer} alt="" />
          </div>
          <div className="form">
            <SignUpForm />
            <div className="hint">
              Already a member?{' '}
              <button
                className="link"
                onClick={() => {
                  setIsSignInOpen(true)
                  setIsSignUpOpen(false)
                }}>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      privacy: false,
      coupon: this.props.auth.coupon,
      errors: {
        username: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
        privacy: '',
      },
      location: '',
      region: '',
    }
  }

  getLocation = async () => {
    const location = await getLocation()

    const region = await getRegion(location)
    this.setState({ location, region })
  }

  onChange = (event) => {
    let err = ''
    if (!event.target.value) {
      err = `${event.target.name} is required`
    } else if (event.target.name === 'privacy') {
      if (event.target.checked) {
        err = ''
      } else {
        err = 'Please check privacy & policy'
      }
    } else if (event.target.name === 'passwordOne') {
      if (event.target.value.length < 6) {
        err = 'Your password should be at least six characters'
      } else {
        err = ''
      }
    } else {
      if (event.target.name === 'passwordTwo') {
        if (event.target.value !== this.state.passwordOne) {
          err = 'Your passwords should be the same'
        } else {
          err = ''
        }
      } else {
        err = ''
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: err,
      },
    })
    this.setState({
      [event.target.name]:
        event.target.name === 'privacy'
          ? event.target.checked
          : event.target.value,
    })
  }

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  async componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(HOME)
    }
    const params = new URLSearchParams(this.props.location.search)
    let coupon = params.get('coupon')
    if (coupon) {
      const id = await checkCoupon(coupon)
      if (id) {
        this.setState({ coupon })
      }
    }
    this.getLocation()
    this.props.getAllSubscriptionProducts()
  }

  componentDidUpdate() {
    const registerError = this.props.auth.registerError
    if (registerError) {
      toast.dismiss()
      toast.error(registerError)

      this.props.removeAuthErrors()
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('sign_up', { method: 'Form' })

    const { username, email, passwordOne, passwordTwo, coupon, region } =
      this.state

    const params = new URLSearchParams(this.props.location.search)
    let duration = params.get('duration')
    let planId = params.get('planId')
    let product
    if (planId) {
      product = this.props.subscriptions.products.find((x) => x._id === planId)
    }

    if (!product) {
      product = this.props.subscriptions.products.find((x) => {
        return (
          x.location === region &&
          x.type === PaymentConstants.subscriptionProduct.pro
        )
      })
      planId = product._id
    }
    if (!duration) duration = PaymentConstants.subscriptionDuration.annually

    const newUser = {
      name: username,
      email: email,
      password: passwordOne,
      password2: passwordTwo,
      coupon: coupon,
      duration,
      planId,
      price: product[PaymentConstants.priceKeys[duration]],
    }

    sessionStorage.setItem('newUser', JSON.stringify(newUser))

    this.props.history.push(PRICING)
    // this.props.registerUser(newUser, this.props.history)
    // setIsSignUpOpen(false)
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      privacy,
      coupon,
      errors: {
        username: usernameErr,
        email: emailErr,
        passwordOne: passwordOneErr,
        passwordTwo: passwordTwoErr,
        privacy: privacyErr,
      },
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne.length < 6 ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      !privacy

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-content">
          <h1>
            Sign up to <b>Cerebriam Studio</b>
          </h1>
          <p>Full Name</p>
          <input
            type="text"
            value={username}
            name="username"
            onChange={this.onChange}
            placeholder="Full Name"
          />
          {usernameErr && <ErrorPill message={usernameErr} />}
          <p>Email Address</p>
          <input
            type="email"
            value={email}
            name="email"
            onChange={this.onChange}
            placeholder="Email Address"
          />
          {emailErr && <ErrorPill message={emailErr} />}

          <div className="input-row">
            <div className="input-col">
              <p>Password</p>
              <input
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              {passwordOneErr && <ErrorPill message={passwordOneErr} />}
            </div>
            <div className="input-col">
              <p>Confirm password</p>
              <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm Password"
              />
              {passwordTwoErr && <ErrorPill message={passwordTwoErr} />}
            </div>
          </div>
          <label style={{ marginTop: 40 }}>
            <input
              type="checkbox"
              name="privacy"
              value={privacy}
              onChange={this.onChange}
              style={{ width: 15, height: 15 }}
            />
            {'  '}
            <a
              target="_blank"
              href="https://web.cerebriam.com/terms-conditions/"
              rel="noreferrer">
              Terms & Conditions
            </a>
          </label>
          <br />
          {privacyErr && <ErrorPill message={privacyErr} />}
          {this.props.auth.isCoupon && (
            <div>
              <p>Promo code</p>
              <input
                name="coupon"
                value={coupon}
                onChange={this.onChange}
                type="text"
                placeholder="Promo (discount) code"
              />
            </div>
          )}
          <button
            className={
              isInvalid || this.props.auth.signUpLoading
                ? 'submit-disabled '
                : 'submit-enabled '
            }
            disabled={isInvalid || this.props.auth.signUpLoading}
            type="submit">
            {this.props.auth.signUpLoading
              ? 'Creating account...'
              : 'Choose Plan'}
          </button>
        </div>
      </form>
    )
  }
}

SignUpFormBase.propTypes = {
  registerUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  getAllSubscriptionProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  subscriptions: state.subscriptions,
})

const SignUpForm = connect(mapStateToProps, {
  registerUser,
  removeAuthErrors,
  getAllSubscriptionProducts,
})(withRouter(SignUpFormBase))

export default SignUpModal
