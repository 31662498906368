import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { Tooltip } from '@chakra-ui/tooltip'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'
import {
  addFilesToScene,
  goToUploadFiles,
  toggleSelectItem,
  deleteMedia,
} from '../../methods/editor/ImportItemsMethods'
import { EditorVideoIcon } from './icons/EditorVideoIcon'
import { EditorSoundIcon } from './icons/EditorSoundIcon'
import { EditorVoiceoverIcon } from './icons/EditorVoiceoverIcon'
import { EditorTextIcon } from './icons/EditorTextIcon'
import { EditorMusicIcon } from './icons/EditorMusicIcon'
import { EditorUploadIcon } from './icons/EditorUploadIcon'
import { EditorInfoIcon } from './icons/EditorInfoIcon'
import { EditorRecordAudioIcon } from './icons/EditorRecordAudioIcon'
import { EditorDeleteIcon } from './icons/EditorDeleteIcon'
import { ConvertDuration } from '../../utils/TimeManage'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import { ONE_SECOND } from '../../constants/Timeline'
import { welcomeSetStep } from '../../redux/actions/RecorderActions'
import {
  toggleWatchProject,
  updateProject,
  storeProjectSteps,
} from '../../redux/actions/EditorActions'
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import {
  AUDIO,
  CHANNEL_DETAILS,
  MUSIC,
  TEXT,
  VIDEO,
  VOICEOVER,
} from '../../constants/Channels'
import EditorTextEditPanel from './EditorTextEditPanel'
import { EditorAudioDuckingIcon } from './icons/EditorAudioDuckingIcon'
const emptyMedia = {
  selMedias: [],
  selPhotos: [],
  selAudios: [],
  selVoiceovers: [],
  selBrands: [],
  selTemplates: [],
}

class EditorSidePanel extends Component {
  constructor(props) {
    super(props)

    this.toggleSelectItem = toggleSelectItem.bind(this)
    this.goToUploadFiles = goToUploadFiles.bind(this)
    this.deleteMedia = deleteMedia.bind(this)
    this.addFilesToScene = addFilesToScene.bind(this)

    this.state = {
      two: 2,
      one: 2,
      ...emptyMedia,
      // showMedia: '',
    }
  }

  clearAllStates = () => {
    this.setState({
      ...emptyMedia,
      // showMedia: '',
    })
    this.props.updateShowSidebarMedia('')
  }

  closeMediaPanel = () => {
    this.clearAllStates()
  }

  addMediaToProject = () => {
    this.addFilesToScene()
    this.clearAllStates()
  }
  handleClickChannel = (channelCode) => {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
    } = this.props
    if (
      !SubscriptionManager(authUser, userSubscription, products)?.isProPlan &&
      [AUDIO.code, VOICEOVER.code, MUSIC.code].includes(channelCode)
    ) {
      alertSubscriptionMessage(
        'Great to see you’re adding more sounds in your masterpiece, try upgrading to Cerebriam Pro to edit the sounds together.',
        true,
      )
    }
    triggerGoogleAnalyticsEvent('add_scene_modal', {
      channelCode,
      userId: this.props.authUser?.id,
    })
    this.setState({
      ...emptyMedia,
    })
    this.props.updateShowSidebarMedia(channelCode)
    const { toggleShowImport } = this.props
    toggleShowImport &&
      channelCode === TEXT.code &&
      toggleShowImport(channelCode)
  }

  toggleVoiceRecorderModal = () => {
    const { toggleShowImport } = this.props
    toggleShowImport && toggleShowImport(VOICEOVER.code)
  }

  isDuckingActive = () => {
    const {
      selectedItems: { selectedSound, selectedMusic, selectedVoiceover },
      showMedia,
    } = this.props
    if (
      selectedSound ||
      selectedMusic ||
      selectedVoiceover ||
      showMedia === MUSIC.code ||
      showMedia === AUDIO.code ||
      showMedia === VOICEOVER.code
    ) {
      return true
    }
    return false
  }

  render() {
    const { videos, voiceovers, photos, audios } = this.props.libraryFiles
    const { selMedias, selPhotos, selBrands, selAudios, selVoiceovers } =
      this.state
    const { project, showMedia } = this.props
    const selectedAspectRatio = project.resolution
    return (
      <div className={`editor-side-panel${showMedia ? ' show-media' : ''}`}>
        <div className="editor-side-panel__content">
          <div className="side-navs">
            <div
              className={`nav${showMedia === VIDEO.code ? ' active' : ''}`}
              onClick={() => this.handleClickChannel(VIDEO.code)}>
              <div className="icon">
                <EditorVideoIcon />
              </div>
              <p>Video</p>
            </div>
            <div
              className={`nav${showMedia === AUDIO.code ? ' active' : ''}`}
              onClick={() => this.handleClickChannel(AUDIO.code)}>
              <div className="icon">
                <EditorSoundIcon />
              </div>
              <p>Sound</p>
            </div>
            <div
              className={`nav${showMedia === VOICEOVER.code ? ' active' : ''}`}
              onClick={() => this.handleClickChannel(VOICEOVER.code)}>
              <div className="icon">
                <EditorVoiceoverIcon />
              </div>
              <p>Voice over</p>
            </div>
            <div
              className={`nav${showMedia === TEXT.code ? ' active' : ''}`}
              onClick={() => this.handleClickChannel(TEXT.code)}>
              <div className="icon">
                <EditorTextIcon />
              </div>
              <p>Title & Text</p>
            </div>
            <div
              className={`nav${showMedia === MUSIC.code ? ' active' : ''}`}
              onClick={() => this.handleClickChannel(MUSIC.code)}>
              <div className="icon">
                <EditorMusicIcon />
              </div>
              <p>Music</p>
            </div>
            <div
              className={`nav${this.isDuckingActive() ? ' active' : ''}`}
              onClick={() => this.props.handleDisplayAudioDuckModal(true)}>
              <div className="icon">
                <EditorAudioDuckingIcon />
              </div>
              <p>Audio Ducking</p>
            </div>
          </div>
          <div className={`media-display${showMedia ? ' show' : ''}`}>
            <div className="media-display__content">
              {showMedia !== TEXT.code && (
                <div className="title">
                  <p>{CHANNEL_DETAILS[showMedia]?.name}</p>
                  <EditorInfoIcon style={{ marginLeft: 7 }} />
                  {(selMedias.length > 0 ||
                    selPhotos.length > 0 ||
                    selAudios.length > 0 ||
                    selVoiceovers.length > 0) && (
                    <EditorDeleteIcon
                      style={{ marginTop: 3 }}
                      onClick={this.deleteMedia}
                    />
                  )}
                  <div className="close-icon" onClick={this.clearAllStates}>
                    X
                  </div>
                </div>
              )}

              {showMedia !== TEXT.code ? (
                <>
                  <div className="add-new-btns">
                    <button
                      className="btn uplaod"
                      onClick={this.goToUploadFiles}>
                      <span className="icon">
                        <EditorUploadIcon />
                      </span>{' '}
                      <span>Upload a file</span>
                    </button>
                    {showMedia === VOICEOVER.code && (
                      <button
                        className="btn"
                        onClick={this.toggleVoiceRecorderModal}>
                        <span className="icon">
                          <EditorRecordAudioIcon />
                        </span>{' '}
                        <span>Record audio</span>
                      </button>
                    )}
                  </div>
                  <div
                    className={`media-items ${
                      showMedia === VOICEOVER.code ? VOICEOVER.code : ''
                    }`}>
                    {showMedia === VIDEO.code &&
                      videos
                        .filter((video) => video.isCropped.some((x) => x))
                        .map((videoAsset, ind) => {
                          const getImageUrl = () => {
                            if (videoAsset.fileType === 'medias') {
                              // At first we choose thumbnails with aspect ratio the same as selected in the editor
                              // If they aren't exist then we choose another one cropped
                              let best_aspect_ratio = 1
                              if (videoAsset.isCropped[selectedAspectRatio])
                                best_aspect_ratio = selectedAspectRatio
                              else if (videoAsset.isCropped[0])
                                best_aspect_ratio = 0
                              else if (videoAsset.isCropped[3])
                                best_aspect_ratio = 3
                              else if (videoAsset.isCropped[2])
                                best_aspect_ratio = 2
                              return videoAsset.fileUrl.replace(
                                /(\.mp4)$/i,
                                `_${best_aspect_ratio}$1`,
                              )
                            }
                            return videoAsset.fileUrl
                          }
                          const imageUrl = getImageUrl()
                          return (
                            <Tooltip
                              key={ind}
                              label={`${videoAsset.fileName}.${
                                videoAsset.extension
                              } - ${moment(videoAsset.date).format(
                                'MMMM Do YYYY, h:mm:ss',
                              )}`}>
                              <div
                                className="media-item"
                                onClick={() => {
                                  this.toggleSelectItem(videoAsset, 'medias')
                                }}>
                                {/* <img src={SceneImg} alt="media" /> */}
                                <LazyLoadImage
                                  src={
                                    imageUrl.indexOf('.MP4') > -1
                                      ? imageUrl.replace('MP4', 'jpg')
                                      : imageUrl.replace('mp4', 'jpg')
                                  }
                                />
                                <span className="media-item-duration">
                                  {' '}
                                  {ConvertDuration(
                                    videoAsset.fileType ===
                                      ASSET_LIBRARY.MEDIA.folderPath
                                      ? videoAsset.duration * ONE_SECOND
                                      : videoAsset.duration,
                                  )}
                                </span>
                                <span
                                  className={`media-item-select${
                                    selMedias.includes(videoAsset)
                                      ? ' selected'
                                      : ''
                                  }`}></span>
                              </div>
                            </Tooltip>
                          )
                        })}

                    {showMedia === VIDEO.code &&
                      photos.map((el, ind) => {
                        return (
                          <div
                            className="media-item"
                            onClick={() => {
                              this.toggleSelectItem(el, 'photos')
                            }}
                            key={ind}>
                            <LazyLoadImage src={el.fileUrl} />
                            <span
                              className={`media-item-select${
                                selPhotos.includes(el) ? ' selected' : ''
                              }`}></span>
                          </div>
                        )
                      })}

                    {showMedia === VOICEOVER.code &&
                      voiceovers.map((el, k) => {
                        return (
                          <div
                            className="media-item"
                            onClick={() => {
                              this.toggleSelectItem(el, 'voiceovers')
                            }}
                            key={k}>
                            <EditorMusicIcon />
                            <span className="media-item-type">
                              .{el.extension}
                            </span>
                            <span className="media-item-name">
                              {el.fileName}.{el.extension}
                            </span>
                            <span
                              className={`media-item-select${
                                selVoiceovers.includes(el) ? ' selected' : ''
                              }`}></span>
                          </div>
                        )
                      })}

                    {showMedia === AUDIO.code &&
                      videos.map((el, k) => (
                        <div
                          className="media-item"
                          onClick={() => {
                            this.toggleSelectItem(el, 'audios')
                          }}
                          key={k}>
                          <span className="media-item-type">
                            .{el.extension}
                          </span>
                          <span className="media-item-name">
                            {el.fileName}.{el.extension}
                          </span>
                          <span
                            className={`media-item-select${
                              selAudios.includes(el) ? ' selected' : ''
                            }`}></span>
                        </div>
                      ))}

                    {showMedia === MUSIC.code &&
                      audios.map((el, k) => {
                        return (
                          <div
                            className="media-item"
                            onClick={() => {
                              this.toggleSelectItem(el, 'audios')
                            }}
                            key={k}>
                            <span className="media-item-type">
                              .{el.extension}
                            </span>
                            <span className="media-item-name">
                              {el.fileName}.{el.extension}
                            </span>
                            <span
                              className={`media-item-select${
                                selAudios.includes(el) ? ' selected' : ''
                              }`}></span>
                          </div>
                        )
                      })}
                  </div>
                  <div className="add-media-btn">
                    <button
                      className={`btn${
                        _.isEmpty(selMedias) &&
                        _.isEmpty(selPhotos) &&
                        _.isEmpty(selAudios) &&
                        _.isEmpty(selVoiceovers)
                          ? ' disabled'
                          : ''
                      }`}
                      onClick={this.addMediaToProject}>
                      Add
                    </button>
                  </div>
                </>
              ) : (
                <EditorTextEditPanel
                  closeMediaPanel={this.closeMediaPanel.bind(this)}
                  showMedia={showMedia}
                  deleteSelectedItems={this.props.deleteSelectedItems}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.editor.project,
  authUser: state.auth.user,
  currentLayout: state.videoCrop.currentLayout,
  selectedItems: state.selectedItems,
  subscriptions: state.subscriptions,
})

const mapDispatchToProps = (dispatch) => ({
  welcomeSetStep: (...args) => dispatch(welcomeSetStep(...args)),
  toggleWatchProject: (...args) => dispatch(toggleWatchProject(...args)),
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
  updateProject: (...args) => dispatch(updateProject(...args)),
  reloadStageByProject: (...args) =>
    dispatch(MainContainerActions.reloadStageByProject(...args)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(EditorSidePanel))
