import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import Header from '../subscribe/Header'
import AuthFooter from '../../components/auth/AuthFooter'
import ProductsFeatures from '../subscribe/ProductsFeatures'
import { PaymentConstants } from '../../constants/Payments'
import { HOME } from '../../constants/Routes'
import Steps from './Steps'
import OrderSummary from './OrderSummary'
import { getLocation, getRegion } from '../../utils/Helper'
import ReviewConfirm from './ReviewConfirm'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const Pricing = () => {
  const dispatch = useDispatch()
  const [activeCycle, setActiveCycle] = useState(2)
  const [activeProduct, setActiveProduct] = useState(2)
  const [active, setActive] = useState(1)
  // const [config, setConfig] = useState({ products: [], pk: null })
  const [region, setRegion] = useState(null)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [regionProductPro, setRegionProductPro] = useState()
  const [regionProductStudio, setRegionProductStudio] = useState()
  const [paymentTypeText] = useState({
    cycleOne: ' Annual Plan - yearly renewal',
    cycleTwo: ' Monthly Plan - monthly renewal',
    cycleThree: ' Pay AS You Go Plan',
    productOne: 'Cerebriam Studio',
    productTwo: 'Cerebriam Pro',
    productThree: 'Pro Enterprise',
  })
  const [registerFormData, setRegisterFormData] = useState(null)
  const history = useHistory()
  const {
    subscriptions: { products, loading: loadingPricing },
    auth,
  } = useSelector((state) => state)
  const isAuthenticated = auth.isAuthenticated
  const [isUserPaying] = useState(isAuthenticated ? true : false)

  const changeActive = (value) => {
    setActive(value)
  }

  const changeActiveCycle = (value) => {
    setActiveCycle(value)
  }

  const changeActiveProduct = (value) => {
    setActiveProduct(value)
    getProductPlan(
      region,
      value === 1
        ? PaymentConstants.subscriptionProduct.studio
        : PaymentConstants.subscriptionProduct.pro,
    )
    setActive(2)
  }

  // const getConfig = async () => {
  //   const configData = await fetch(
  //     process.env.REACT_APP_API_URL + PaymentConstants.configURL,
  //   ).then((r) => r.json())
  //   setConfig(configData)
  // }

  const loadSubscriptionProducts = () => {
    dispatch(SubscriptionActions.getAllSubscriptionProducts())
  }

  const getProductPlan = async (
    reg = region,
    type = PaymentConstants.subscriptionProduct.pro,
  ) => {
    const myPlan = products.find(
      (p) => p.location.toLowerCase() === reg && p.type === type,
    )
    setCurrentPlan(myPlan)
  }

  const getUserLocation = async () => {
    const location = await getLocation()
    const region = await getRegion(location)
    setRegion(region)
    const getRegionProductpro = products.find(
      (product) =>
        product.location === region &&
        product.type === PaymentConstants.subscriptionProduct.pro,
    )
    const getRegionProductStudio = products.find(
      (product) =>
        product.location === region &&
        product.type === PaymentConstants.subscriptionProduct.studio,
    )
    setRegionProductPro(getRegionProductpro)
    setRegionProductStudio(getRegionProductStudio)
    getProductPlan(region)
  }

  useEffect(() => {
    if (isAuthenticated && !isUserPaying) {
      history.push(HOME)
    }
  }, [isAuthenticated])

  useEffect(() => {
    loadSubscriptionProducts()
  }, [])

  useEffect(() => {
    getUserLocation()
  }, [products])

  return (
    <Elements stripe={stripePromise}>
      <div className={'cerebriam-subscribe'}>
        <div className="masthead py-12 navHeader">
          <Header />
          <div
            className="cerebriam-order cerebriam-container"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
            }}>
            <h2 style={{ color: '#555', fontSize: 30, fontWeight: 400 }}>
              Choose your trial package
            </h2>
            {/* <p style={{ fontSize: 16, color: '#555' }}>
              Just starting out? Don’t worry we’ve got your back. Need a
              reliable plan to help with your growing professional video
              production needs? We’ve got you covered. No matter where you are
              on your journey to creating great video, or the size of your
              budget, we can help:
            </p> */}
            {loadingPricing && (
              <div style={{ textAlign: 'center' }}>
                <p>
                  <FontAwesomeIcon className="spinner" icon={faSpinner} />
                </p>
              </div>
            )}
            {!loadingPricing && (
              <>
                {active !== 1 && (
                  <Steps active={active} changeActive={changeActive} />
                )}
                <ProductsFeatures
                  products={products}
                  paymentTypeText={paymentTypeText}
                  pageOn="pricing"
                  show={active === 1}
                  regionProductPro={regionProductPro}
                  regionProductStudio={regionProductStudio}
                  changeActiveCycle={changeActiveCycle}
                  changeActiveProduct={changeActiveProduct}
                />
                <OrderSummary
                  activeCycle={activeCycle}
                  changeActiveCycle={changeActiveCycle}
                  activeProduct={activeProduct}
                  changeActive={changeActive}
                  currentPlan={currentPlan}
                  paymentTypeText={paymentTypeText}
                  show={active === 2}
                  setRegisterFormData={setRegisterFormData}
                />
                <ReviewConfirm
                  activeCycle={activeCycle}
                  activeProduct={activeProduct}
                  paymentTypeText={paymentTypeText}
                  currentPlan={currentPlan}
                  show={active === 3}
                  registerFormData={registerFormData}
                />
              </>
            )}
          </div>
        </div>
        <div className="" style={{ margin: '0 auto' }}>
          <AuthFooter />
        </div>
      </div>
    </Elements>
  )
}

export default Pricing
